import botPattern from '@config/botPattern';

export const isUserAgentBot = (userAgent: string | undefined) => {
  const regexBot = new RegExp(botPattern, 'i');
  let isUserABot = false;

  if (userAgent) {
    return regexBot.test(userAgent);
  }

  if (typeof navigator !== 'undefined') {
    isUserABot = regexBot.test(navigator?.userAgent);
  }
  return isUserABot;
};

export const isBot = (userAgent?: string) => isUserAgentBot(userAgent);
