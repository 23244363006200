import Environment from '@config/index';
import { Preferences } from '@interfaces/models/preferences';
import { FooterContent } from '@interfaces/models/footer';
import { axios } from '@config/axios';
import cachedAxios from '@config/cached-axios';
import { HeaderDataItem } from '@interfaces/models/header';
import { I18n } from '@interfaces/models/i18n';
import { SizeGuide } from '@interfaces/models/sizeGuide';
import { ProductSalesCondition } from '@interfaces/models/product-sales-condition';
import { Country } from '@interfaces/models/country';
import { PaymentLogo, StaticPaymentLogo, StaticPaymentLogoList } from '@interfaces/models/paymentMethod';

const BASE_URL = typeof window !== 'undefined' ? Environment.apiStaticBaseUrl : Environment.apiStaticSSRBaseUrl;

type StaticApiParams = Pick<Preferences, 'locale' | 'siteId'>;

// Returns only the first level elements. The rest are loaded on demand, to reduce initial payload
export const getPartialHeader = async ({ siteId, locale }: StaticApiParams): Promise<HeaderDataItem[]> => {
  const { data } = await cachedAxios.get<HeaderDataItem[]>(`${BASE_URL}/${siteId}/${locale}/header.json`);
  return data.map((headerItem: HeaderDataItem) => {
    const { content, ...rest } = headerItem;
    return rest;
  });
};

export const getFullHeader = async ({ siteId, locale }: StaticApiParams): Promise<HeaderDataItem[]> => {
  const { data } = await axios.get<HeaderDataItem[]>(`${BASE_URL}/${siteId}/${locale}/header.json`);
  return data;
};

export const getI18n = async ({ siteId, locale }: StaticApiParams): Promise<I18n> => {
  const { data } = await axios.get<I18n>(`${BASE_URL}/${siteId}/${locale}/I18N.json`);
  return data;
};

export const getFooter = async ({ siteId, locale }: StaticApiParams): Promise<FooterContent> => {
  const { data } = await axios.get<FooterContent>(`${BASE_URL}/${siteId}/${locale}/footer.json`);
  return data;
};

export const getSizeGuide = async ({ siteId, locale }: StaticApiParams): Promise<SizeGuide> => {
  const { data } = await axios.get<SizeGuide>(`${BASE_URL}/${siteId}/${locale}/sizeGuide.json`);
  return data;
};

export const getProductSalesCondition = async ({ siteId, locale }: StaticApiParams): Promise<ProductSalesCondition> => {
  const { data } = await axios.get<ProductSalesCondition>(`${BASE_URL}/${siteId}/${locale}/productSalesCondition.json`);
  return data;
};

export const getPayoneerSupportedBanks = async ({ siteId, locale }: StaticApiParams): Promise<[]> => {
  const { data } = await axios.get(`${BASE_URL}/${siteId}/${locale}/payoneerRegPayeeFormat.json`);
  return data?.bank_name?.list?.list_items ?? [];
};

export const getTransferMethodCountries = async ({ siteId, locale }: StaticApiParams): Promise<Country[]> => {
  const { data } = await axios.get<Country[]>(`${BASE_URL}/${siteId}/${locale}/hyperwalltSupportedCountries.json`);
  return (data ?? []).sort((a: Country, b: Country) => a.name.localeCompare(b.name));
};

type PaymentLogosApiParams = Pick<Preferences, 'locale' | 'siteId' | 'country' | 'currency'>;
export const getPaymentLogos = async ({
  siteId,
  locale,
  country,
  currency,
}: PaymentLogosApiParams): Promise<PaymentLogo[]> => {
  const { data } = await axios.get<StaticPaymentLogoList>(
    `${BASE_URL}/${siteId}/${locale}/paymentLogoEligibilityWeb.json`,
  );
  return (
    data?.list
      // get logos that match the country and currency
      ?.filter((logo: StaticPaymentLogo) => {
        return logo.countries.includes(country) && logo.currencies.includes(currency);
      })
      // filter to get unique values
      .filter((logo, index, self) => index === self.findIndex((l) => l.mnemonic === logo.mnemonic))
      // map to the required format
      .map((logo: StaticPaymentLogo) => {
        return { mnemonic: logo.mnemonic, path: `${data.host}${logo.mnemonic}.svg` };
      })
  );
};
